export const tableColumn = [
	{
		label: '角色名称',
		prop: 'name',
		width: '200px',
	},
	{
		label: '角色类型',
		prop: 'type',
		width: '200px',
		isCustomize: true,
		tableType: 'roleType',
	},
	{
		label: '数据权限',
		prop: 'dataType',
		width: '200px',
		isCustomize: true,
		tableType: 'dataType',
	},
	{
		label: '角色排序',
		prop: 'sort',
		width: '200px',
	},

	{
		label: '操作',
		prop: 'name',
		fixed: 'right',
		isCustomize: true,
		tableType: 'roleOperate',
	},
]

export const forms = [
	[
		{
			label: '角色名称',
			type: 'input',
			attr: 'name',
			placeholder: '请输入角色名称',
			column: 24,
			labelWidth: '120px',
		},
	],
	[
		{
			label: '角色类型',
			type: 'radio',
			attr: 'type',
			placeholder: '请选择角色类型',
			column: 24,
			labelWidth: '120px',
			option: [
				{ label: '管理员', value: 0 },
				{ label: '普通用户', value: 1 },
			],
		},
	],

	[
		{
			label: '排序',
			type: 'inputNumber',
			attr: 'sort',
			placeholder: '请输入顺序',
			column: 24,
			labelWidth: '120px',
		},
	],
]

export const dataSettingForms = [
	[
		{
			label: '数据权限类型',
			type: 'radio',
			attr: 'dataType',
			placeholder: '请选择权限类型',
			column: 24,
			labelWidth: '120px',
			option: [
				{ label: '个人', value: 0 },
				{ label: '部门', value: 1 },
			],
		},
	]
]
