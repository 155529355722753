<template>
  <div class="xinsheng-warp system-warp">
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @submit="handleFilterSubmit"
      @reset="handleReset"
    />
    <div class="system-btn user-btn">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleNew"
        >添加角色</el-button
      >
      <el-button size="small" @click="batchDelete">删 除</el-button>
    </div>
    <div class="container">
      <div class="right">
        <RoleTable
          v-if="tableData"
          :tableColumn="tableColumn"
          :tableData="tableData"
          :selection="true"
          :numIndex="true"
          numWidth="100px"
          @selection="handleSelectionChange"
          @edit="goToEdit"
          @delete="goToDelete"
          @view="goToView"
          @setting="handleSetting"
          @dataSetting="handleDataSetting"
        />
        <RolePagination
          :total="total"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <RoleDialog
      :title="title"
      :isShowDialog="isShowDialog"
      :rules="rules"
      :forms="forms"
      :formData="formData"
      :width="500"
      @submit="handleSubmit"
      @close="handleColse"
    />
    <DialogView
      title="查看"
      :isShowDialog="isShowView"
      :forms="forms"
      :formData="formData"
      :width="300"
      @close="handleViewColse"
    />
    <DialogTree
      v-if="treeData"
      title="角色权限设置"
      :isShowDialog="isSetting"
      :width="440"
      :treeData="treeData"
      :defaultSelectKeys="defaultSelectKeys"
      @close="handleRoleClose"
      @submit="handleRoleSubmit"
      ref="treeRef"
    />

    <RoleDialog
      title="角色数据权限设置"
      :isShowDialog="isDataSetting"
      :forms="dataSettingForms"
      :formData="dataSettingFormData"
      :width="500"
      @submit="handleDataSettingSubmit"
      @close="handleDataSettingColse"
    />
  </div>
</template>

<script>
import FilterForm from "@/components/filter";
import { computed, onMounted, reactive, ref } from "vue";
import { ElButton, ElInput, ElMessage, ElMessageBox, ElTree } from "element-plus";
import { tableColumn, forms, dataSettingForms } from "./index.js";
import RoleTable from "@/components/table";
import RolePagination from "@/components/pagination";
import RoleDialog from "@/components/dialog-form";
import DialogView from "@/components/dialog-view";
import DialogTree from "@/components/dialog-tree";
import { useStore } from "vuex";
import {
  postRole,
  putRole,
  deleteRole,
  batchDeleteRole,
  postRoles,
  settingDataType,
} from "@/api/method/system";
import { reset, reMoveButtons, getButtons } from "@/until";

export default {
  name: "SystemRole",
  components: {
    FilterForm,
    [ElButton.name]: ElButton,
    [ElInput.name]: ElInput,
    [ElTree.name]: ElTree,
    RoleTable,
    RolePagination,
    RoleDialog,
    DialogView,
    DialogTree,
  },
  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      search: null,
      type: null,
    });
    const formData = reactive({
      id: null,
      name: null,
      type: 1,
      sort: null,
    });

    const dataSettingFormData = reactive({
      id: null,
      dataType: 1,
    });

    const filters = reactive([
      [
        {
          label: "角色名称",
          type: "input",
          attr: "search",
          placeholder: "搜索角色名称",
          suffixIcon: "el-icon-search",
          column: 7,
          labelWidth: "80px",
        },
        {
          label: "角色类型",
          type: "select",
          attr: "type",
          placeholder: "请选择",
          column: 6,
          option: [
            { label: "管理员", val: 0 },
            { label: "普通用户", val: 1 },
          ],
          labelWidth: "100px",
        },
        {
          type: "button",
          column: 11,
        },
      ],
    ]);

    const pagination = reactive({
      page: 1,
      size: 10,
    });

    const company = ref("");
    const tableData = computed(() => state.rolePageList && state.rolePageList.records);
    const total = computed(() => state.rolePageList && state.rolePageList.total);
    const treeData = computed(() => state.menuTree && state.menuTree.data);

    const isShowDialog = ref(false);
    const title = ref("添加");

    const handleNew = () => {
      isShowDialog.value = true;
    };
    const handleColse = () => {
      isShowDialog.value = false;
      reset(formData);
      formData.type = 1;
    };

    const getList = () => {
      dispatch("rolePageList", { ...pagination, ...filterData });
    };

    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    onMounted(() => {
      getList();
      //   getDepartTree();
      //   getRoleList();
    });
    const idArr = ref([]);
    const handleSelectionChange = (arr) => {
      idArr.value = arr.map((item) => item.id);
    };

    const isSetting = ref(false);
    const isDataSetting = ref(false);
    const defaultSelectKeys = ref([]);
    const treeRef = ref(null);
    const userId = ref(null);
    const handleSetting = async (id) => {
      defaultSelectKeys.value = [];
      userId.value = id;

      await dispatch("menuTree", { roleId: id });
      defaultSelectKeys.value = state.menuTree.roleMenus;
      isSetting.value = true;
    };

    const handleDataSetting = async (id) => {
      await dispatch("roleDetail", id);
      getRoleDetail(id);
      dataSettingFormData.dataType = state.roleDetail.dataType;
      dataSettingFormData.id = state.roleDetail.id;
      isDataSetting.value = true;
    };

    const handleRoleClose = () => (isSetting.value = false);

    const handleDataSettingColse = () => (isDataSetting.value = false);

    const handleNodeClick = () => {};
    const rules = reactive({
      name: [{ required: true, message: "请输入角色名", trigger: "submit" }],
    });

    const handleSubmit = async () => {
      const { id, ...params } = formData;
      if (id) {
        const res = await putRole({ id, ...params });
        if (res.code == 200) {
          ElMessage.success("编辑成功！");
          handleColse();
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      } else {
        const res = await postRole(params);
        if (res.code == 200) {
          ElMessage.success("保存成功！");
          handleColse();
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      }
    };
    const getRoleDetail = (id) => {
      const { name, type, sort } = state.roleDetail;
      formData.id = id;
      formData.name = name;
      formData.type = type;
      formData.sort = sort;
    };
    const goToEdit = async (id) => {
      isShowDialog.value = true;
      title.value = "编辑";
      await dispatch("roleDetail", id);
      getRoleDetail(id);
    };

    const goToDelete = (id) => {
      ElMessageBox.confirm("确定要删除该角色吗?", "提示", {
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await deleteRole(id);
          if (res.code == 200) {
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            getList();
          } else {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch(() => {});
    };

    const isShowView = ref(false);
    const goToView = async (id) => {
      isShowView.value = true;
      await dispatch("roleDetail", id);
      getRoleDetail(id);
      formData.type = state.roleDetail.type ? "普通用户" : "管理员";
    };

    const handleViewColse = () => {
      isShowView.value = false;
      reset(formData);
      formData.type = 1;
    };

    const batchDelete = () => {
      if (!idArr.value.length) {
        ElMessage.error("请选择要删除的角色");
        return;
      }
      ElMessageBox.confirm("确定要删除这些角色吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await batchDeleteRole(idArr.value);
          if (res.code == 200) {
            idArr.value = [];
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            getList();
          } else {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch(() => {});
    };

    const handleFilterSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };

    const handleRoleSubmit = async () => {
      const res = await postRoles({
        id: userId.value,
        menuIds: treeRef.value.getCheckedKeys(),
      });
      reMoveButtons();
      if (res.code == 200) {
        ElMessage.success("保存成功");
        await dispatch("menuRouters");
        const buttonArr = await getButtons(state.menuRouters);
        localStorage.removeItem("buttons");
        localStorage.setItem("buttons", buttonArr);
        handleRoleClose();
      } else {
        ElMessage.error(res.msg);
      }
    };

    const handleDataSettingSubmit = async () => {
      const res = await settingDataType(dataSettingFormData);

      if (res.code == 200) {
        ElMessage.success("保存成功");
        handleDataSettingColse();
        getList();
      } else {
        ElMessage.error(res.msg);
      }
    };

    return {
      filters,
      filterData,
      company,
      tableColumn,
      tableData,
      handleNew,
      isShowDialog,
      rules,
      forms,
      dataSettingForms,
      dataSettingFormData,
      formData,
      total,
      handleSelectionChange,
      treeData,
      handleNodeClick,
      handleSubmit,
      handleColse,
      title,
      goToEdit,
      goToDelete,
      goToView,
      handleViewColse,
      isShowView,
      batchDelete,
      handleSetting,
      handleDataSetting,
      isSetting,
      isDataSetting,
      defaultSelectKeys,
      treeRef,
      handleRoleClose,
      handleFilterSubmit,
      handleReset,
      handleRoleSubmit,
      handleDataSettingColse,
      handleDataSettingSubmit,
      currentChange,
      sizeChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-btn {
  padding-bottom: 40px;
}
.container {
  display: flex;
  height: auto;
  .left {
    width: 250px;
    background: #f5f6f9;
    margin-right: 24px;
    padding: 20px;
  }
  .right {
    flex: 1;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>

<style lang="scss">
.company-block {
  margin-bottom: 20px;
}

.company-block .el-input__inner {
  background: white !important;
}
.depart-tree {
  background: #f5f6f9;
  .el-tree-node__content {
    background: #f5f6f9;
  }
}
</style>
