<template>
  <el-dialog
    :title="title"
    :width="width"
    v-model="showDialog"
    :before-close="handleColse"
  >
    <el-tree
      ref="treeRef"
      :data="treeData"
      show-checkbox
      node-key="id"
      default-expand-all
      :default-checked-keys="defaultSelectKeys"
      :props="{
          children: 'userMenuModels',
          label: 'menuName',
      }"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="handleColse"
          size="small"
        >取消</el-button>
        <el-button
          type="primary"
          @click="submitForm"
          size="small"
        >保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElButton, ElDialog, ElTree } from "element-plus";
import { computed, reactive, ref } from "vue";

export default {
  name: "DialogFilter",
  props: ["title", "isShowDialog", "width", "treeData", "defaultSelectKeys"],
  components: {
    [ElDialog.name]: ElDialog,
    [ElTree.name]: ElTree,
    [ElButton.name]: ElButton,
  },
  setup(props, context) {
    const formDatas = reactive(props.formData);
    const submitForm = () => {
      context.emit("submit");
    };

    const showDialog = computed(() => props.isShowDialog);

    const resetForm = () => {};
    const handleColse = () => context.emit("close");
    // const handleCheckChange = () => {
    //   console.log(props.defaultSelectKeys);
    // };

    const treeRef = ref(null);

    const getCheckedKeys = () => treeRef.value.getCheckedKeys();

    return {
      submitForm,
      formDatas,
      resetForm,
      showDialog,
      handleColse,
    //   handleCheckChange,
      treeRef,
      getCheckedKeys,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/css/form";
</style>